import React, { Component, useState, useEffect } from "react";
import Header1 from "./../../components/header1/header1";
import Paragraph from "../../components/paragraph/paragraph";
import Nav from "./../../components/navbar/navbar";
import Grid from "@material-ui/core/Grid";
import Footer from "./../../components/footer/footer";
import Hidden from "@material-ui/core/Hidden";

const padding = 30;

export default function Results() {
  return (
    <div>
      <Nav selected="ourstory" />
      <div style={{ width: "100%", height: "40vh" }}>
        <img
          src={require("./../../assets/ourStory/header.jpg")}
          style={{
            height: "40vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div style={{ position: "absolute", height: "40vh", width: "100%" }}>
          <Grid
            container
            style={{
              paddingLeft: padding,
            }}
          >
            <Grid item xs={0} sm={2} md={1} lg={1} xl={1} />
            <Grid
              item
              xs={12}
              sm={8}
              md={7}
              lg={7}
              xl={7}
              style={{
                height: "40vh",
                width: "100%",
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Header1
                  style={{
                    color: "white",
                    fontSize: "5vh",
                  }}
                >
                  Our Story
                </Header1>
              </div>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
        </div>
      </div>
      <Grid container style={{padding:padding, paddingTop:70}}>
        <Grid item xs={0} sm={2} md={1} lg={1} xl={1} />
        <Grid container xs={12} sm={8} md={10} lg={10} xl={10}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Header1 style={{ color: "#22422b" }}>
              Carbon Down was Born in the MOuntains of COLORADO.
            </Header1>
            <Paragraph>
            Our founders, Ian and Leif, spent much of their lives growing up in the western forests of the US, with Leif in Southern California, and Ian in Colorado.

However, these two states have had some of the worst fires in decades, with many of the forests they grew up in as kids burning down in catastrophic fires. It was sad, but everyone thought the forests would recover. 
            </Paragraph>
            <Header1 style={{ color: "#22422b" }}>
            Years after the fires, no trees grew back.
            </Header1>
            <Paragraph>
            Because the​ fires were so intense, they destroyed all the trees and seeds in the soil. ​

After ​realizing that these once vast forests were not going to grow back, they decided to found Carbon Down to not only protect and restore not only their forests, but forests across the US. By using carbon offset funding from people like you, they are able to leverage it into measureable conservation benefits for our local forests.
            </Paragraph>
            <Header1 style={{ color: "#22422b" }}>
            It only takes a seedling to start a forest.
            </Header1>
            <Paragraph>
            Fixing all of the problems facing our forests will require systemic change in the way we manage our forests to ensure that they remain healthy in perpetuity. ​

We won't stop until all of our forests in the US are safe and healthy, knowing that the forests we protect and the forests we restore will forever stir in the mountain breeze.
            </Paragraph>
            <Header1 style={{ color: "#22422b" }}>
            How To Support Our Mission
            </Header1>
            <Paragraph>
            We work directly with companies and organizations to scale our impact. If you would like to support us, please refer us to  organizations you have in your network would would benefit from our support or carbon neutrality service.
            </Paragraph>
            
          </Grid>
          <Hidden smDown>
          <Grid item xs={0} sm={0} md={2} lg={2} xl={2} />
          <Grid item xs={0} sm={0} md={5} lg={5} xl={5}>
              <img
              src={require("./../../assets/ourStory/tree.jpg")}
              style={{
                height: "100vh",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={0} sm={2} md={1} lg={1} xl={1} />
      </Grid>

      <Footer />
    </div>
  );
}
